.shop-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
  margin-top: 15px;

  .item {
    flex: 1;

    .image {
      display: flex;
      justify-content: center;
      padding: 5px 0 5px 0;
    }

    .desc {
      color: #666;
      text-align: center;
      font-size: 13px;
      line-height: 16px;
      cursor: pointer;
    }

    .desc:hover {
      color: #e60500;
      text-decoration: underline;
    }

    .desc-narrow {
      padding: 0 30px;
    }

    @media screen and (max-width: 1024px) {
      .desc-narrow {
        padding: 0 16px;
      }
    }
  }
}

.shop-iframe {
  width: 800px;
  height: 85vh;
  padding-top: 20px;
  border: none;
  overflow: auto;
}