.exhibition-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 20px 0 20px;

  .exhibition-item {
    position: relative;
    width: calc((100% - 12px) / 2);
    display: flex;
    justify-content: flex-start;
    padding: 15px 20px 0 20px;
    margin-bottom: 12px;
    cursor: pointer;

    .exhibition-title {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 224px;
      height: 60px;
      border-radius: 0px 0px 20px 0px;
      font-size: 26px;
      font-family: Gilmer Heavy;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
      line-height: 60px;
      cursor: pointer;
      z-index: 1;
    }

    .main-product {
      width: 31.5%;
      margin-top: 20%;
      position: relative;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        max-width: 190px;
        min-width: 150px;
        height: auto;
      }

      .mian-product-name {
        font-size: 16px;
        font-weight: 500;
        color: #343434;
        line-height: 20px;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;   // 显示的行数 
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-top: 28px;
      }

      .mian-product-price {
        font-size: 36px;
        // font-weight: 600;
        color: #FF4E00;
        padding-top: 14px;
        font-family: rubik,sans-serif;

        i {
          font-size: 22px;
          font-style: normal;
        }

        span {
          font-size: 20px;
          font-weight: 400 !important;
          text-decoration: line-through;
          color: #afafaf !important;
          padding-left: 5px;
        }
      }

      .mian-product-discount {
        position: absolute;
        top: -24px;
        right: -8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        background: #e60501;
        border-radius: 50%;

        .discount-num {
          font-size: 36px;
          // font-weight: 600;
          color: #FDFDFD;
        }

        .discount-text {
          font-size: 36px;
          // font-weight: 500;
          color: #FDFDFD;

          .discount-unit {
            font-size: 21px;
            font-weight: 500;
            color: #FDFDFD;            
          }

          .discount-off {
            font-size: 12px;
            color: #FDFDFD;
            transform: scale(0.75);
            margin-top: -6px;
          }
        }
      }
    }

    .product-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 51.5%;
      padding-left: 10%;
      box-sizing: content-box;

      .product-item {
        width: 48%;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 13px;
        cursor: pointer;

        img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 auto;
        }

        .product-name {
          height: 44px;
          font-size: 12px;
          color: #7E7E7E;
          line-height: 14px;
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 2;   // 显示的行数 
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-top: 16px;
        }
        .product-price {
          font-size: 22px;
          // font-weight: 600;
          color: #FF4E00;
          padding-top: 5px;
          font-family: rubik,sans-serif;
          i {
            font-size: 18px;
            font-style: normal;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1320px) and (min-width: 1270px){
  .exhibition-list {

    .exhibition-item {

      .product-list {
        padding-left: 13%;
      }
    }
  }
}

@media screen and (max-width: 1270px) and (min-width: 960px){
  .exhibition-list {

    .exhibition-item {

      .exhibition-title {
        width: 186px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
      }

      .product-list {
        padding-left: 13%;
      }
    }
  }
}

@media screen and (max-width: 960px){
  .exhibition-list {
    display: block;

    .exhibition-item {
      width: 100%;

      .main-product {
        width: 26.5%;

        img {
          max-width: 230px;
        }
      }
      .product-list {
        padding-left: 13%;
      }
    }
  }
}