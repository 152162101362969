@import './_variables.scss';

.crazySale-box {
  width: 244px;
  max-height: 400px;
  background: #FFFFFF;
  margin-left: 12px;
  padding: 10px 8px;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: inset -18px 0 30px 0 rgba(255, 255, 255, 0.5);
  .title {
    height: 30px;
    line-height: 30px;
    text-align: center;

    em {
      font-style: italic;
      font-size: 20px;
      font-weight: 700;
      color: #f90;
      text-shadow: 6px 0 3px #ffe9c9;
      -moz-text-shadow: -6px 0 3px #ffe9c9;
    }
  }

  .time {
    display: flex;
    align-items: center;
    margin: 4px 0 6px;

    .countDown {
      width: 104px;
      height: 30px;
      margin: 0 2px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .hh,
      .ss,
      .mm {
        text-align: center;

        .h,
        .s,
        .m {
          background: #e60500;
          display: inline-block;
          margin: 0 1px;
          width: 13px;
          padding: 2px 0;
          font-style: normal;
          color: #fff;
          border-radius: 3px;
        }
      }
    }

    .tip {
      width: 82px;
      line-height: 12px;
      font-size: 12px;
      color: #666;
    }
  }

  .commodity {
    display: flex;
    justify-content: center;
    padding: 4px 0;
    width: 210px;
    height: 210px;
    margin: 0 auto;
    cursor: pointer;
  }

  .description {
    padding: 8px 12px 0px 12px;
    cursor: pointer;

    .description-name {
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: #606060;
      line-height: 18px;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;   // 显示的行数 
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .price-desc {
      display: flex;
      align-items: flex-end;
      padding-top: 6px;
      font-family: rubik,sans-serif;

      .price {
        margin-right: 4px;

        span {
          color: #e60500;
          font-size: 29px;
          font-weight: 700;

          i {
            font-size: 18px;
            font-style: normal;
          }
        }
      }

      .marking-price {
        padding-bottom: 3px;
        
        span {
          color: #afafaf;
          font-size: 20px;
          padding-left: 10px;
          text-decoration: line-through;

          i {
            font-size: 20px;
            font-style: normal;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
    .crazySale-box {
      display: none;
    }
}