.reviews {
  display: flex;
  justify-content: space-between;

  .content {
    width: 100%;
    height: 500px;
    background-color: #fff;
    padding: 24px 14px;
    box-sizing: border-box;
    margin-right: 12px;
    overflow: hidden;

    .title {
      display: flex;
      align-items: center;
      padding-bottom: 20px;

      .googleIcon {
        font-size: 46px;
      }

      p {
        font-size: 28px;
        color: #2F2F2F;
        font-weight: bold;
        margin-left: 16px;
      }
    }

    .swiper-box {
      height: 400px;
    }

    .item {
      padding: 18px;
      background-color: #F5F5F5;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 20px;

      .info {
        display: flex;
        align-items: center;
        height: 28px;

        .user-name {
          font-size: 16px;
          line-height: 34px;
          color: #333333;
          font-weight: 600;
          margin-right: 20px;
        }

        .time {
          font-size: 14px;
          line-height: 34px;
          color: #999999;
          margin-left: auto;
        }
      }

      .commit {
        margin-top: 10px;

        p {
          height: 48px;
          font-size: 14px;
          line-height: 16px;
          color: #333333;
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 3; // 显示的行数 
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }

  .bottom-subscribe {
    position: relative;
    width: 224px;
    height: 500px;
    flex-shrink: 0;

    img {
      display: block;
    }

    .subscribe-form {
      position: absolute;
      right: 48px;
      right: 12px;
      bottom: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 38px;
      border-radius: 2px;
      overflow: hidden;

      input {
        width: 100%;
        height: 38px;
        background: #fff;
        border: none;
        padding: 8px 10px;
      }

      .subscribe-btn {
        width: 90px;
        height: 38px;
        background-color: #f1cd77;
        color: #e72043;
        padding: 10px 4px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}


.crazySale-review {
  padding: 20px 0;

  &-main {
    padding: 0 24px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .summary-of-comments {
    padding: 24px;
    margin-top: 24px;
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;

    .crazySale-info {
      display: flex;
      align-items: flex-start;

      .info {
        margin-left: 30px;

        h2 {
          font-size: 30px;
          font-weight: bold;
        }

        .info-review {
          display: flex;
          align-items: center;
          margin: 8px 0;

          .point {
            color: #D90B17;
            font-size: 30px;
            font-weight: bold;
          }

          .rate {
            margin: 0 12px;
          }

          .positive {
            font-size: 14px;
            color: #333333;
          }
        }
      }
    }

    .summary-data {
      display: flex;
      align-items: center;

      .chart-list {
        margin-right: 24px;
      }

      .summary {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .reviewTotal {
          color: #D90B17;
          font-weight: bold;
          font-size: 30px;
        }
      }
    }
  }

  .ux-box {
    margin-top: 24px;
    padding: 0 24px 0 0;
    background-color: #fff;
    display: flex;
    align-items: center;

    &-image {
      width: 600px;

      img {
        display: block;
      }
    }

    .ux-info {
      margin-left: 34px;
      word-break: break-all;

      h2 {
        color: #222;
        font-weight: bold;
        font-size: 20px;
      }

      p {
        font-size: 15px;
        color: #222;
        margin-top: 12px;
      }
    }
  }

  .review-list {
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;

    h2 {
      color: #000;
      font-size: 20px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;

      span {
        font-weight: bold;
        font-size: 22px;
        color: #D90B17;
      }
    }

    .see-more-reviews {
      text-align: right;
    }
  }

  .hot-new-list {
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;

    h2 {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 24px;
    }

    .view-more {
      text-align: center;
      margin-top: 24px;
    }
  }

}

.goods-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &-item {
    width: calc(20% - 16px);
    margin-bottom: 12px;
  }
}