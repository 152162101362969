@import './_variables.scss';
.page-index {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding-bottom: 40px;
  
  .slide-doll {
    position: fixed;
    top: 182px;
    right: calc(((100% - 1440px) / 2) - 108px);
    z-index: 0;
    transition: opacity 0.3s ease;
  }
  @media (max-width: 1630px) {
    .slide-doll {
      display: none;
    }
  }

  .advertise-box {
    display: flex;
    justify-content: flex-start;
    margin-left: 256px;
  }

  .shop-info-box {
    position: relative;
    width: 100%;
    max-width: 1444px;
    margin-top: 12px;
    margin: 0 auto;
    z-index: 1;
  }

  .shop-info {
    background-color: #ffffff;
    margin-left: 256px;
  }

  .crazy-deals {
    position: relative;
    width: 100%;
    max-width: 1444px;
    padding: 0 20px;
    margin: 0 auto;
    padding-top: 24px;
    z-index: 1;
  }

  .exhibition-box {
    width: 100%;
    max-width: 1444px;
    margin: 0 auto;
    padding-top: 24px;
  }

  .middle-subscribe-box {
    display: block;
    position: relative;
    width: 100%;
    max-width: 1444px;
    margin: 0 auto;
    padding: 12px 20px 0 20px;
  }

  @media screen and (max-width: 1560px) and (min-width: 1200px){
    .middle-subscribe-box {
      width: calc(100% - 120px);
    }
  }

  @media screen and (max-width: 1200px) and (min-width: 1024px){
    .middle-subscribe-box {
      width: calc(100% - 160px);
      padding: 0;
    }
  }
  
  @media screen and (max-width: 1024px){
    .middle-subscribe-box {
      width: calc(100% - 40px);
      padding: 0;
    }
  }

  .fixed-subscribe {
    position: fixed;
    bottom: 160px;
    right: 20px;
    width: 350px;
    min-height: 238px;
    background-color: #e72143;
    border: 1px solid #ddd;
    overflow: hidden;
    z-index: 999;

    .subscribe-close {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .subscribe-form {
      position: absolute;
      bottom: 16px;
      width: 100%;
      padding: 0 20px;

      input {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        color: #929292;
        text-align: center;
        padding: 8px 8px;
        margin-bottom: 5px;
      }

      .subscribe-btn {
        width: 100%;
        height: 32px;
        background-color: #f1cd77;
        font-size: 14px;
        font-weight: 500;
        color: #e72043;
        text-align: center;
        padding: 6px 8px;
      }
    }
  }

  .recommend-box {
    width: 100%;
    max-width: 1444px;
    margin: 0 auto;
    padding: 24px 20px 0 20px;
  }

  .reviews-box {
    width: 100%;
    max-width: 1444px;
    margin: 0 auto;
    padding: 24px 20px 0 20px;
  }
}

@media screen and (max-width: 1560px) and (min-width: 1024px){
  .page-index {

    .crazy-deals {
      width: calc(100% - 120px);
    }

    .exhibition-box {
      width: calc(100% - 120px);
    }

    .recommend-box {
      width: calc(100% - 120px);
    }
  
    .reviews-box {
      width: calc(100% - 120px);
    }

    .advertise-box {
      margin-left: 260px;
    }

    .shop-info-box {
      width: calc(100% - 120px);
      .shop-info {
        margin-left: 260px;
      }
    }
  }
}

@media #{$media-query-small} {
    .page-index {
      .advertise-box {
        margin-left: 170px;
      }
      .shop-info-box {
        padding-right: 20px;
      }
      .shop-info-box {
        .shop-info {
          margin-left: 170px;
        }
      }
    }
}