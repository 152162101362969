.recommend {
  .title {

    h2 {
      font-size: 28px;
      color: #2F2F2F;
      font-weight: bold;
      text-align: center;
    }
  }

  .content {
    padding-top: 20px;

    .recommend-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      .recommend-item {
        width: calc((100% - 60px) / 6);
        max-width: 224px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
      }
    }
  }

  .view-more {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .load-btn {
      width: 168px;
      background-color: #e60501;
      border-radius: 22px;
      color: #fff;
      font-size: 18px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .load-btn:hover {
      color: #fff;
    }
  }
}

.advertising {
  padding: 40px 15px 44px;
  background-color: red;

  .title {
    color: #FDD984;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
  }

  .ad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
      width: 178px;
      height: 42px;
      color: #FF3A0D;
      background-color: #fff;
      border-radius: 22px;
      margin-bottom: 18px;
      font-size: 20px;
    }
  }
}


@media screen and (max-width: 1340px) and (min-width: 1150px){
  .recommend {

    .content {
      .recommend-list {
        .recommend-item {
          width: calc((100% - 48px) / 5);
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) and (min-width: 850px){
  .recommend {

    .content {
      .recommend-list {
        .recommend-item {
          width: calc((100% - 36px) / 4);
        }
      }
    }
  }
}

@media screen and (max-width: 850px){
  .recommend {

    .content {
      .recommend-list {
        .recommend-item {
          width: calc((100% - 24px) / 3);
        }
      }
    }
  }
}