.status-box {
  font-family: Gilmer Medium;
  font-weight: 600;

  .status-free-shipping, .status-on-sale, .status-reduced, .status-new {
    height: 22px;
  }

  .status-free-shipping {
    display: inline-block;
    background: #DBF3FF;
    font-size: 12px;
    color: #0071B2;
    line-height: 22px;
    margin: 0;
    padding: 0 5px;
    margin-right: 4px;
  }

  .status-on-sale {
    display: inline-block;
    background: #E5FFEA;
    font-size: 12px;
    color: #00B345;
    line-height: 22px;
    margin: 0;
    padding: 0 5px;
    margin-right: 4px;
  }

  .status-reduced {
    display: inline-block;
    background: #FFEFE9;
    font-size: 12px;
    color: #FF3E29;
    line-height: 22px;
    margin: 0;
    padding: 0 5px;
    margin-right: 4px;
  }

  .status-new {
    display: inline-block;
    background: #FFEFE9;
    font-size: 12px;
    color: #FF250D;
    line-height: 22px;
    margin: 0;
    padding: 0 5px;
    margin-right: 4px;
  }

  .status-top_pick {
    display: inline-block;
    background: #f4e9ff;
    font-size: 12px;
    color: #7e00ed;
    line-height: 22px;
    margin: 0;
    padding: 0 5px;
    margin-right: 4px;
  }

  .status-clearance {
    display: inline-block;
    background: #fff0d5;
    font-size: 12px;
    color: #a56200;
    line-height: 22px;
    margin: 0;
    padding: 0 5px;
    margin-right: 4px;
  }
}