@import './_variables.scss';

.swiper-box {
  flex: 1;
  overflow: hidden;
  position: relative;
  z-index: 9;

  .banner-item {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .banner-item-bg {
    height: 556px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
}
@media screen and (max-width: 1560px) and (min-width: 1024px){
  .swiper-box {
    width: 865px;
  }
}

@media #{$media-query-small} {
  .swiper-box {
    .banner-item-bg{
      height: 300px;
    }
  }
}