.weekly-deals-box {
  display: flex;
  align-items: center;
  background-color: #fff;

  .deals {
    width: 224px;
    height: 100%;

    .main-img {
      width: 224px;
      height: auto;
    }

    .pad-img {
      display: none;
      width: 100%;
    }
  }

  .crazy-deals-goods {
    flex: 1;
    height: 100%;
    margin-left: 20px;
    overflow: hidden;
    padding: 12px 0;
    position: relative;

    .product-list {
      width: 224px;
      padding: 0 12px;
    }

    .gradient-mask-right {
      position: absolute;
      right: 0;
      top: 0;
      width: 80px;
      height: 100%;
      z-index: 5;
      background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, .8));
    }

    .gradient-mask-left {
      position: absolute;
      left: 0;
      top: 0;
      width: 80px;
      height: 100%;
      z-index: 5;
      background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, .8));
    }
  }
}


@media screen and (max-width: 1270px) and (min-width: 930px){
  .crazyDeals-box {
    display: block;
    height: auto;

    .deals {
      width: 100%;
      height: auto;
  
      .main-img {
        display: none;
        width: 224px;
        height: auto;
      }
  
      .pad-img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .crazy-deals-goods {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 930px){
  .crazyDeals-box {
    display: block;
    height: auto;

    .deals {
      width: 100%;
      height: auto;
  
      .main-img {
        display: none;
        width: 224px;
        height: auto;
      }
  
      .pad-img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .crazy-deals-goods {
      margin-left: 0;

      .product-list {
        width: 182px;
      }
    }
  }
}