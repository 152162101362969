.goods-box {
  background-color: #fff;
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;

  .goodsLink {
    position: relative;
    z-index: 100;
  }

  .imgthumb {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
      max-height: 200px;
      object-fit: contain;
    }

    .act_user_sel {
      position: absolute;
      top: 0;
      z-index: 102;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .1);
      display: none;

      .sel_add {
        position: absolute;
        bottom: 0;
        z-index: 103;
        width: 100%;
        background-color: rgba(255, 255, 255, .9);
        padding: 10px;
        box-sizing: border-box;
        display: none;

        .add_addtocart_btn {
          width: 100%;
          height: 32px;
          padding-top: 4px;
          padding-bottom: 4px;
          font-size: 14px;
          background-color: #d50b17;
          border-radius: 8px;
          color: #fff;
        }

        .pre_order_btn {
          width: 100%;
          background-color: #ff8300;
          border-radius: 8px;
          border-color: #ff8300;
          color: #fff;
        }
      }

      .added {
        background-color: rgba(255, 255, 255, .9);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        position: absolute;
        bottom: 0;
        z-index: 15;
        width: 100%;
        // height: 60%;
        text-align: center;
        display: none;

        .addtip {
          padding: 10px 0 8px;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-shopping-cart {
            color: #000;
            vertical-align: middle;
            font-size: 18px;
            margin-right: 3px;
          }
        }

        .cart_btn,
        .checkout_btn {
          width: 90%;
          margin: 0 auto;
          display: inline-block;
          border-radius: 10px;
          color: #fff;
          font-size: 13px;
        }

        .cart_btn {
          background-color: #d50b17;
          margin-bottom: 8px;
        }

        .checkout_btn {
          background-color: #008044;
        }
      }

      .cart_btn:hover,
      .checkout_btn:hover,
      .add_addtocart_btn:hover {
        border: none;
      }
    }
  }

  .imgthumb:hover .act_user_sel {
    display: block;

    .sel_add {
      display: block;
    }

    .added {
      display: none;
    }
  }

  .goods-info {
    margin-top: 8px;
    min-height: 122px;

    .goods-name {
      height: 40px;
      color: #606060;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      cursor: pointer;
    }

    .goods-price {
      display: flex;
      align-items: baseline;
      padding-top: 4px;
      font-family: rubik,sans-serif;

      .price {
        color: red;
        margin-right: 8px;

        b {
          font-size: 28px;

          i {
            font-size: 24px;
            font-style: normal;
          }
        }
      }

      .crossedPrice {
        b {
          font-size: 18px;
          color: #afafaf;
          text-decoration: line-through;
          font-weight: 400;
        }
      }
    }

    .notify-box {
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        height: 30px;
        font-size: 12px;
        padding: 7.2px 39px;
      }
    }

    .goods-start {
      margin-bottom: 8px;

      .commitNum {
        margin-left: 8px;
        font-size: 12px;
        color: #898989;
      }
    }
    .goods-start-hidden {
      visibility: hidden;
    }

    .coupon-deals {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 24px;
      line-height: 24px;

      .desc {
        height: 100%;
        background-color: #EDEDED;
        color: #606060;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        padding: 0 18px;
      }

      .icon {
        width: 26px;
        line-height: 24px;
        background-color: #e60501;
        color: #fff;
        font-size: 16px;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
      }
    }
  }

  .tag {
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    padding: 4px 16px;
    background-color: #B10C17;
    border-bottom-left-radius: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
  }
}

.goods-box.Recommend {
  padding: 12px;
  // box-sizing: content-box;
}

// 为首页crazy Deals商品适配
@media screen and (max-width: 930px){
  .goods-box {

    .goods-info {

      .goods-price {
  
        .price {
          color: red;
          margin-right: 8px;
  
          b {
            font-size: 20px !important;
  
            i {
              font-size: 16px !important;
            }
          }
        }
  
        .crossedPrice {
          b {
            font-size: 14px !important;
            color: #898989;
            text-decoration: line-through;
            font-weight: 400;
          }
        }
      }
    }
  }
}

// 为首页crazy Deals商品适配
@media screen and (max-width: 930px){
  .goods-box {

    .goods-info {

      .goods-price {
  
        .price {
          color: red;
          margin-right: 8px;
  
          b {
            font-size: 18px !important;
  
            i {
              font-size: 14px !important;
            }
          }
        }
  
        .crossedPrice {
          b {
            font-size: 14px !important;
            color: #898989;
            text-decoration: line-through;
            font-weight: 400;
          }
        }
      }

      .coupon-deals {
        .desc {
          padding: 0 8px !important;
        }
      }
    }
  }
}