.middle-subscribe {
  display: block;
  position: relative;
  width: 100%;
  max-width: 1444px;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .subscribe-form {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 340px;
    height: 40px;
    border-radius: 3px;
    overflow: hidden;

    input {
      width: 100%;
      height: 40px;
      background: #fff;
      border: none;
      padding: 8px 10px;
    }

    .subscribe-btn {
      width: 100px;
      height: 40px;
      background-color: #f1cd77;
      color: #e72043;
      padding: 12px 12px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }
  }
}
.middle-subscribe-ipad {
  display: none;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 5% 20px 5%;

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .subscribe-form {
    position: absolute;
    bottom: 4%;
    left: 1%;
    width: 98%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
    overflow: hidden;

    input {
      width: 60%;
      height: 40px;
      background: #fff;
      border: none;
      padding: 8px 10px;
    }

    .subscribe-btn {
      width: 40%;
      height: 40px;
      background-color: #f1cd77;
      color: #e72043;
      padding: 12px 12px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1560px) and (min-width: 1200px){
  .middle-subscribe-ipad {
    display: none;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1024px){
  .middle-subscribe {
    display: none;
  }

  .middle-subscribe-ipad {
    display: block;
    padding: 0 !important;
  }
}

@media screen and (max-width: 1024px){
  .middle-subscribe {
    display: none;
  }

  .middle-subscribe-ipad {
    display: block;
    padding: 0 !important;
  }
}